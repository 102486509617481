* {
  margin: 0;
}

body {
  font-family: "Sora", sans-serif;
}

p {
  color: #232536;
  /* font-family: "lato"; */
}

.text-center {
  text-align: center;
}

.App {
  overflow: hidden;
}

.slick-track {
  display: flex;
  align-items: center;
}

.call-hero-button-area a:nth-child(2) {
  background: unset;
  border: 1px solid #fff;
  color: #fff;
  margin-right: 0px;
}

.navbar__link.header-login-btn {
  color: #fff;
  background: #1b3f73;
  border-radius: 6px;
  padding: 4px 25px;
  border: 2px solid transparent;
  transition: 0.3s;
  margin-left: 10px;
  position: relative;
}
a.navbar__link.header-login-btn:hover {
  background: #fff;
  border: 2px solid #1b3f73;
  color: #1b3f73;
}

a.navbar__link.header-registration-btn {
  border: 2px solid #1b3f73;
  padding: 6px 25px;
  margin-left: 12px;
  display: inline-block;
  border-radius: 6px;
}

a.navbar__link.header-registration-btn:hover {
  background: #1b3f73;
  color: #fff;
  transition: 0.5s;
}

.App.home-page-main-bg {
  background: #fafcff;
}
.teacher-single-item p {
  font-size: 14px;
}
.font-w-500 > p {
  font-weight: 600;
  font-size: 18px;
}
.our-customer h2 {
  font-weight: 700;
  font-size: 34px;
}
.footer-single-item-content > a > img {
  height: 55px;
}
.teacher-single-item.text-center.course-ware-logo img {
  width: unset;
  height: auto;
}
