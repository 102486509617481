.feature-single-item {
  text-align: center;
}
.feature-items-wrap .marquee {
  gap: 4vw;
}
.feature-items-wrap .marquee-container {
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: 100%;
  gap: 4vw;
}
.feature-single-item img {
  display: inline-block;
  height: 38px;
  filter: grayscale(1);
}

.feature-single-item img:hover {
  filter: grayscale(0);
}

.feature-items-wrap {
  position: relative;
}

.feature-main-area .slick-slider.slider-wrap.slick-initialized {
  width: 80%;
  margin-left: auto;
}

.our-customer h6 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #00000090;
  margin-bottom: 5px;
}

.our-customer h4 {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.our-customer {
  height: 100%;
  background: #f9fbff;
  position: absolute;
  z-index: 99;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  padding-left: 0;
}

@media only screen and (max-width: 770px) {
  .our-customer {
    width: 160px;
    position: relative;
    margin: 30px auto;
    background: transparent;
  }
  .our-customer {
    width: 160px;
    justify-content: center;
    text-align: center;
    margin-top: 0;
    margin-bottom: 50px;
  }
  .feature-main-area .slick-slider.slider-wrap.slick-initialized {
    width: 100%;
  }

  .our-customer {
    width: 160px;
  }

  .our-customer h6 {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #00000090;
  }

  .our-customer h4 {
    font-size: 13px;
  }
}

.feature-main-area {
  padding: 70px 0;
  background: #f9fbff;
  z-index: 99;
  position: relative;
  overflow: hidden;
}
