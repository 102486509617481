.hero-slider-content-left {
  padding-left: 25px;
}

.teacher-single-item h2 {
  font-weight: 700;
  color: #1b3f73;
  font-size: 36px;
  line-height: 46px;
}
.service-main-page {
  padding: 60px 0;
}
.teacher-single-item.course-ware-area h2 {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.teacher-single-item.course-ware-area h2 > h6 {
  margin-bottom: 0;
  margin-left: 25px;
  font-size: 20px;
}
span.course-logo-color-text {
  color: #583bab;
}
span.ware-logo-color-text {
  color: #f7b941;
}
.course-ware-area-know-more-btn button {
  background: #1b3f73;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
}
.student-container {
  padding-top: 50px;
}
.calibrax-course-ware {
  padding-top: 50px;
}
