.header-navbar ul li.active {
  color: red;
}

.header-main-area {
  padding: 10px 0;
  box-shadow: 0px 3px 10px 0px rgb(27 63 115 / 7%);
  margin-bottom: 20px;
  background: #fff;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.header-inner-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-navbar ul > li {
  list-style: none;
  display: inline-block;
}

.header-navbar ul > li > a {
  text-decoration: none;
  padding: 10px 15px;
  color: #000;
  font-size: 14px;
}

.menuToggle {
  position: absolute;
  right: 0;
}

.navbar_mobile_menu_bar {
  width: 100%;
  height: 100%;
}

.navbar_mobile_menu_bar span:nth-child(3) {
  margin-bottom: 0;
}

.navbar_mobile_menu_bar span {
  display: block;
  width: 25px;
  height: 2px;
  background: #000;
  margin-bottom: 6px;
  transition: 0.5s;
}

.navbar_mobile_close.remove {
  position: absolute;
  right: -15px;
  z-index: 999999;
}

.navbar_mobile_close.remove span {
  display: block;
  width: 26px;
  height: 2px;
  background: #000;
  transition: 0.3s;
}

.navbar_mobile_close.remove span:nth-child(1) {
  transform: rotate(45deg);
  position: relative;
  top: 1px;
  right: 12px;
}

.navbar_mobile_close.remove span:nth-child(2) {
  transform: rotate(-45deg);
  position: relative;
  top: 0px;
  right: 12px;
}

.navbar_mobile_close.remove span:nth-child(3) {
  display: none;
}
.header-navbar ul {
  margin-bottom: 0;
}
.menuToggle {
  display: none;
}

.navbar__link {
  border-bottom: 3px solid transparent;
}

.navbar__link--active {
  color: #1b3f73 !important;
  /* border-bottom: 3px solid #1b3f73; */
  transition: border-bottom 0.5s ease-in-out;
  font-weight: 700;
}
.header-logo img {
  height: 60px;
}
@media only screen and (max-width: 992px) {
  .header-logo img {
    width: 120px;
  }

  .menuToggle {
    display: block;
  }

  nav.navbar_mobile_menu.show {
    transition: 0.3s;
    position: absolute;
    z-index: 999;
    width: 112%;
    height: 90vh;
    right: -6%;
    top: 45px;
    background: #fff;
    transform-origin: right;
    transform: scale(1);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }

  nav.navbar_mobile_menu.hide {
    position: absolute;
    right: 0;
    width: 0;
    opacity: 0;
    transform: scale(0);
    transition: 0.3s;
    top: 75px;
    height: 100%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
  }

  nav.navbar_mobile_menu.show ul {
    width: 90%;
    margin-top: 30px;
  }

  nav.navbar_mobile_menu.show ul > li {
    display: block;
    padding: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }
}
/* .header-logo a {
  height: 55px;
} */

.header-logo a img {
  height: 100%;
}

.header-logo a img {
  width: 140px;
}
