.hero-main-wrap {
  display: flex;
  align-items: center;
  margin-top: 110px;
}
.hero-main-wrap > * {
  height: 100%;
}

.hero-main-wrap > * > * {
  height: 100%;
}
.hero-main-wrap > * > * > * {
  height: 100%;
}
.hero-main-wrap > * > * > * > * {
  height: 100%;
}

.hero-inner-wrap {
  width: 100%;
  background: #fff;
  position: relative;
  box-shadow: 0px 4px 7px 0px #2b478b17;
  border-radius: 10px;
  padding: 30px;
}

/* .hero-right-image {
  position: absolute;
  width: 65%;
  right: 0;
  bottom: 0;
  text-align: right;
} */

.hero-slider-content-left {
  padding-left: 25px;
}

.hero-right-image img {
  width: 100%;
  display: inline-block;
}

.hero-slider-content-left h2 span {
  color: red;
  display: inline-block;
  margin-right: 7px;
}

.hero-slider-content-left h2 {
  font-weight: 700;
  font-size: 50px;
  line-height: 62px;
  color: #1b3f73;
}
.hero-slider-content-left p {
  font-size: 18px;
}
.header-login-dropdown.dropdown button {
  border: none;
  background: transparent;
  padding: 0 0px;
}
.dropdown-toggle::after {
  display: none;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem transparent;
}
.navbar__link.header-login-btn .dropdown-menu.show {
  display: block;
  top: 30px !important;
  text-align: center;
  left: unset !important;
  right: 0 !important;
}
span.font-smaller {
  font-size: 13px;
}
a.dropdown-item {
  border: 1px solid #1b3f73;
  border-radius: 5px;
  margin: 10px 10px;
}
a.dropdown-item {
  border: 1px solid #1b3f73;
  border-radius: 5px;
  margin: 10px 10px;
  width: 180px;
}
button.hero-area.navbar__link.header-login-btn {
  margin: 0;
  margin-top: 30px;
}
.teacher-single-item img {
  width: 100%;
}
.hero-slider-single-item > .row {
  height: 100%;
}
@media only screen and (max-width: 992px) {
  .service-main-page {
    text-align: center;
  }
  .hero-slider-single-item > .row {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
    height: 100%;
    flex-flow: wrap-reverse;
  }
  .student-container > .row {
    flex-flow: wrap-reverse;
  }
  .our-customer {
    height: 100%;
    background: transparent;
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    padding-left: 0;
    justify-content: center;
    margin: auto;
    text-align: center;
    margin-bottom: 30px;
  }
  div#Techer_Panel {
    margin-bottom: 50px;
  }
  .student-container {
    margin-bottom: 50px;
  }
  .teacher-single-item.course-ware-area {
    margin-top: 30px;
  }
  .teacher-single-item.course-ware-area h2 > h6 {
    margin-left: 15px;
  }
  .slick-slider.slider-wrap.slick-initialized {
    width: unset;
    /* margin-left: auto; */
  }
  .teacher-single-item.course-ware-area h2 {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: center;
  }
  .hero-main-wrap {
    height: unset;
  }
  .hero-slider-content-left h2 {
    font-size: 40px;
    line-height: 60px;
    margin-top: 20px;
  }
  button.hero-area.navbar__link.header-login-btn {
    margin-bottom: 20px;
  }
  .teacher-single-item img {
    width: 100%;
    height: unset;
  }
  .teacher-single-item.text-center.course-ware-logo img {
    height: 100%;
    width: unset;
  }
  .feature-main-area .slick-slider.slider-wrap.slick-initialized {
    width: 100%;
    margin-left: auto;
  }
  .feature-single-item img {
    height: 40px;
  }
  .navbar__link.header-login-btn .dropdown-menu.show {
    display: block;
    top: 70px !important;
    text-align: center;
    left: 0 !important;
    transform: unset !important;
  }
  a.dropdown-item {
    margin: auto;
  }
}
@media only screen and (max-width: 769px) {
  .teacher-single-item h2 {
    font-size: 30px !important;
    line-height: 42px !important;
  }
  .font-w-500 > p {
    font-weight: 600;
    font-size: 16px;
  }
  .teacher-single-item p {
    font-size: 14px;
  }
  .teacher-single-item.text-center.course-ware-logo img {
    height: unset;
    width: 100%;
  }
  .hero-slider-content-left {
    padding-left: 0px !important;
  }
  .col-lg-4.col-md-6.col-6.display-none-responsive-device {
    display: none;
  }
  .footer-single-item-content > a > img {
    height: 50px;
  }
  .hero-slider-content-left h2 {
    font-size: 30px;
    line-height: 42px;
    margin-top: 20px;
  }
  .feature-single-item img {
    height: 30px;
  }
}

.hero-area_btn-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    justify-content: center;
  }
  @media (max-width: 576px) {
    a {
      display: block !important;
      width: 100%;
    }
  }
  a.hero-area.navbar__link.header-login-btn {
    display: inline-block;
    padding: 8px 24px;
    text-decoration: none;
    margin-left: 0;
    // &.demo-request-btn {
    //   // background: #fff;
    //   // color: #1b3f73;
    //   // border: 2px solid #1b3f73;
    //   &:hover {
    //     background: #1b3f7399;
    //     color: #fff;
    //     border: 2px solid #1b3f73;
    //   }
    // }
  }
}
